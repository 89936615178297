import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faL } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useRouteAccessList } from '@hooks';
const Sidebar = (props) => {
  const { t } = props;
  const [data, setData] = useState([]);
  const [setting, setSetting] = useState(false);
  const [master, setMaster] = useState(false);
  const [user, userMaster] = useState(false);
  const [project, setProject] = useState(false);
  const [hrms, setHRMS] = useState(false);
  const location = useLocation();
  useRouteAccessList((res) => {
    setData(res.data.route_key);
  });
  const shouldShowRoute = (route) => {
    console.log('data:', data)
    if (data) {
      return data.includes(route);
    }
  };
  // Sub Module States
  // Projects
  const [projectLink, setProjectLink] = useState(false);
  const [dbFieldLink, setDBFieldLink] = useState(false);
  // User
  const [userLink, setUserLink] = useState(false);
  const [roleLink, setRoleLInk] = useState(false);
  // HRMS
  const [attendanceLink, setAttendanceLink] = useState(false);
  const [punchInOutLink, setPunchInOutLink] = useState(false);
  const [holidaysETCLink, setHolidaysETCLink] = useState(false);
  const [branchLink, setBranchLink] = useState(false);
  const [departmentLink, setDepartmentLink] = useState(false);
  // Master
  const [translationsLink, setTranslationLink] = useState(false);
  const [emailTemplateLink, setEmailTemplateLink] = useState(false);
  const [faqCategoryLink, setFAQCategoryLink] = useState(false);
  const [faqsLink, setFAQSLink] = useState(false);
  // Setting
  const [generalLink, setGeneralLink] = useState(false);
  useEffect(() => {
    if (location) {
      // Pathname
      const pathName = location.pathname;
      // Project And DB Field Type
      setProject(
        pathName.includes('/projects') || pathName.includes('/dbfieldtype') ? true : false
      );
      setProjectLink(pathName.includes('/projects') ? true : false);
      setDBFieldLink(pathName.includes('/dbfieldtype') ? true : false);
      // User And Roles
      userMaster(pathName.includes('/user') || pathName.includes('/roles') ? true : false);
      setUserLink(pathName.includes('/user') ? true : false);
      setRoleLInk(pathName.includes('/roles') ? true : false);
      // HRMS
      setHRMS(
        pathName.includes('/attendance') ||
          pathName.includes('/punch-in-out') ||
          pathName.includes('/branch') ||
          pathName.includes('/department') ||
          pathName.includes('/holidays-etc')
          ? true
          : false
      );
      setAttendanceLink(pathName.includes('/attendance') ? true : false);
      setPunchInOutLink(pathName.includes('/punch-in-out') ? true : false);
      setHolidaysETCLink(pathName.includes('/holidays-etc') ? true : false);

      setBranchLink(pathName.includes('/branch') ? true : false);
      setDepartmentLink(pathName.includes('/department') ? true : false);
      // Master
      setMaster(
        pathName.includes('/translations') ||
          pathName.includes('/email-template') ||
          pathName.includes('/faq-category') ||
          pathName.includes('/faq')
          ? true
          : false
      );
      setTranslationLink(pathName.includes('/translations') ? true : false);
      setEmailTemplateLink(pathName.includes('/email-template') ? true : false);
      setFAQCategoryLink(pathName.includes('/faq-category') ? true : false);
      setFAQSLink(pathName.includes('/faq') && !pathName.includes('/faq-category') ? true : false);
      // Setting
      setSetting(pathName.includes('/general') ? true : false);
      setGeneralLink(pathName.includes('/general') ? true : false);
    }
  }, [location]);
  return (
    <>
      <aside id="sidebar-wrapper" className={props?.active ? `sidebar_collapsed` : ''}>
        <div className="sidebar mt-4">
          <nav className="navigation">
            <ul className="main-submenu">
              <li>
                <NavLink to="/dashboard">
                  <span>
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      stroke="#21407D"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="13.8333"
                        y="3"
                        width="7.16667"
                        height="7.16667"
                        rx="2"
                        stroke="#21407D"
                        strokeWidth="2"
                      />
                      <rect
                        x="13.8333"
                        y="13.8333"
                        width="7.16667"
                        height="7.16667"
                        rx="2"
                        stroke="#21407D"
                        strokeWidth="2"
                      />
                      <rect
                        x="3"
                        y="13.8333"
                        width="7.16667"
                        height="7.16667"
                        rx="2"
                        stroke="#21407D"
                        strokeWidth="2"
                      />
                      <rect
                        x="3"
                        y="3"
                        width="7.16667"
                        height="7.16667"
                        rx="2"
                        stroke="#21407D"
                        strokeWidth="2"
                      />
                    </svg>
                  </span>
                  <label>{t('page.sidebar_dashboard')}</label>
                </NavLink>
              </li>
            </ul>
            {shouldShowRoute('projects') || shouldShowRoute('dbfieldtype') ? (
              <ul className="main-submenu">
                <li>
                  <span className="sidebar-li-arrow">
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 512 512"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_108_3680)">
                          <path
                            d="M256 0C220.8 0 192 28.8 192 64V448C192 483.2 220.8 512 256 512C291.2 512 320 483.2 320 448V64C320 28.8 291.2 0 256 0ZM277.333 448C277.333 459.733 267.733 469.333 256 469.333C244.267 469.333 234.667 459.733 234.667 448V64C234.667 52.2667 244.267 42.6667 256 42.6667C267.733 42.6667 277.333 52.2667 277.333 64V448ZM448 128C412.8 128 384 156.8 384 192V448C384 483.2 412.8 512 448 512C483.2 512 512 483.2 512 448V192C512 156.8 483.2 128 448 128ZM469.333 448C469.333 459.733 459.733 469.333 448 469.333C436.267 469.333 426.667 459.733 426.667 448V192C426.667 180.267 436.267 170.667 448 170.667C459.733 170.667 469.333 180.267 469.333 192V448ZM64 256C28.8 256 0 284.8 0 320V448C0 483.2 28.8 512 64 512C99.2 512 128 483.2 128 448V320C128 284.8 99.2 256 64 256ZM85.3333 448C85.3333 459.733 75.7333 469.333 64 469.333C52.2667 469.333 42.6667 459.733 42.6667 448V320C42.6667 308.267 52.2667 298.667 64 298.667C75.7333 298.667 85.3333 308.267 85.3333 320V448Z"
                            fill="#21407D"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_108_3680">
                            <rect width="512" height="512" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <label onClick={() => setProject(!project)} aria-expanded={project}>
                      {t('page.sidebar_projects')}
                    </label>
                    <small
                      className="right-side-set-arrow"
                      onClick={() => setProject(!project)}
                      aria-expanded={project}>
                      <FontAwesomeIcon
                        icon={project ? faChevronUp : faChevronDown}
                        className="dropdown_icon"
                      />
                    </small>
                  </span>
                  <Collapse in={project} className="p-0">
                    <ul className="submenu">
                      {shouldShowRoute('projects') && (
                        <li>
                          <NavLink to="/projects/list" className={projectLink ? 'active' : ''}>
                            {t('page.sidebar_project')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('dbfieldtype') && (
                        <li>
                          <NavLink to="/dbfieldtype/list" className={dbFieldLink ? 'active' : ''}>
                            {t('page.sidebar_dbfieldtype')}
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
              </ul>
            ) : null}
            {shouldShowRoute('role') || shouldShowRoute('user') ? (
              <ul className="main-submenu">
                <li>
                  <span className="sidebar-li-arrow">
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                          stroke="#21407D"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                          stroke="#21407D"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <label onClick={() => userMaster(!user)} aria-expanded={user}>
                      {t('page.sidebar_users')}
                    </label>
                    <small
                      className="right-side-set-arrow"
                      onClick={() => userMaster(!user)}
                      aria-expanded={user}>
                      <FontAwesomeIcon
                        icon={user ? faChevronUp : faChevronDown}
                        className="dropdown_icon"
                      />
                    </small>
                  </span>
                  <Collapse in={user} className="p-0">
                    <ul className="submenu">
                      {shouldShowRoute('role') && (
                        <li>
                          <NavLink to="/roles/list" className={roleLink ? 'active' : ''}>
                            {t('page.sidebar_roles')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('user') && (
                        <li>
                          <NavLink to="/user/list" className={userLink ? 'active' : ''}>
                            {t('page.sidebar_users')}
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
              </ul>
            ) : null}
            {shouldShowRoute('attendance') ||
            shouldShowRoute('biometric') ||
            shouldShowRoute('branch') ||
            shouldShowRoute('department') ||
            shouldShowRoute('holidays-etc') ? (
              <ul className="main-submenu">
                <li>
                  <span className="sidebar-li-arrow">
                    <span>
                      <svg
                        fill="#21407D"
                        height="24"
                        width="24"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 495.485 495.485"
                        stroke="#21407D">
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <g id="XMLID_158_">
                            <path
                              id="XMLID_162_"
                              d="M387.998,105.937h-17.765c-22.969,0-41.605,18.629-41.605,41.605v3.052l52.523-37.304 c0.048-0.034,0.109-0.04,0.159-0.074c-0.05,0.04-0.099,0.049-0.142,0.088l-69.363,63.773l-71.121,30.29 c-10.505,4.464-15.397,16.605-10.93,27.108c3.359,7.862,10.997,12.578,19.036,12.578c2.7,0,5.444-0.534,8.073-1.664l74.398-31.676 c2.18-0.921,4.164-2.205,5.896-3.804l71.973-66.187c0.061-0.055,0.08-0.138,0.142-0.193c-0.061,0.064-0.08,0.144-0.13,0.209 L345.55,219.04c-1.841,2.179-4.081,3.803-6.539,4.859l-2.891,1.228l-7.492,3.187l0.031,242.372c0,13.698,11.112,24.8,24.8,24.8 c5.653,0,10.819-1.971,14.98-5.167c-3.18-5.861-5.164-12.48-5.164-19.633V322.901h16.536v147.784c0,13.698,11.107,24.8,24.8,24.8 c13.688,0,24.793-11.102,24.793-24.8c0,0,0.196-208.497,0.196-323.144C429.601,124.566,410.971,105.937,387.998,105.937z"></path>
                            <path
                              id="XMLID_161_"
                              d="M379.13,96.635c26.704,0,48.323-21.625,48.323-48.313C427.454,21.625,405.835,0,379.13,0 c-26.684,0-48.321,21.625-48.321,48.322C330.809,75.01,352.447,96.635,379.13,96.635z"></path>
                            <path
                              id="XMLID_160_"
                              d="M116.355,96.635c26.685,0,48.323-21.625,48.323-48.313C164.678,21.625,143.04,0,116.355,0 C89.653,0,68.034,21.625,68.034,48.322C68.034,75.01,89.653,96.635,116.355,96.635z"></path>
                            <path
                              id="XMLID_159_"
                              d="M177.092,173.825l-55.15-53.198l44.916,34.664v-7.749c0-22.976-18.636-41.605-41.605-41.605H107.49 c-22.977,0-41.605,18.629-41.605,41.605c0,118.313,0.196,323.144,0.196,323.144c0,13.698,11.107,24.8,24.793,24.8 c13.693,0,24.8-11.102,24.8-24.8V322.901h16.536v147.784c0,7.153-1.99,13.772-5.17,19.633c4.169,3.196,9.332,5.167,14.987,5.167 c13.686,0,24.8-11.102,24.8-24.8l0.031-242.66l-16.504-11.737l-5.275-3.748c-0.307-0.224-0.602-0.5-0.841-0.783l-51.005-61.376 l57.089,55.063c1.295,1.234,2.733,2.309,4.299,3.175l68.13,37.954c-1.714-2.194-3.243-4.559-4.391-7.238 c-5.734-13.475-1.743-28.505,8.682-37.675L177.092,173.825z"></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <label onClick={() => setHRMS(!hrms)} aria-expanded={hrms}>
                      {t('page.sidebar_hrms')}
                    </label>
                    <small
                      className="right-side-set-arrow"
                      onClick={() => setHRMS(!hrms)}
                      aria-expanded={hrms}>
                      <FontAwesomeIcon
                        icon={hrms ? faChevronUp : faChevronDown}
                        className="dropdown_icon"
                      />
                    </small>
                  </span>
                  <Collapse in={hrms} className="p-0">
                    <ul className="submenu">
                      {shouldShowRoute('attendance') && (
                        <li>
                          <NavLink to="/attendance/list" className={attendanceLink ? 'active' : ''}>
                            {t('page.sidebar_attendance')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('biometric') && (
                        <li>
                          <NavLink
                            to="/punch-in-out/list"
                            className={punchInOutLink ? 'active' : ''}>
                            {t('page.sidebar_punch_in_out')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('holidays-etc') && (
                        <li>
                          <NavLink
                            to="/holidays-etc/list"
                            className={holidaysETCLink ? 'active' : ''}>
                            {t('page.sidebar_holidays_etc')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('branch') && (
                        <li>
                          <NavLink to="/branch/list" className={branchLink ? 'active' : ''}>
                            {t('page.sidebar_branch')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('department') && (
                        <li>
                          <NavLink to="/department/list" className={departmentLink ? 'active' : ''}>
                            {t('page.sidebar_department')}
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
              </ul>
            ) : null}
            <hr style={{ width: '85%', height: '2px', marginLeft: '7%' }} />
            {shouldShowRoute('translations') ||
            shouldShowRoute('email-template') ||
            shouldShowRoute('faq-category') ||
            shouldShowRoute('faq') ? (
              <ul className="main-submenu">
                <li>
                  <span className="sidebar-li-arrow">
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 18.5C1 16.0147 3.01472 14 5.5 14L18.5 14C20.9853 14 23 16.0147 23 18.5C23 20.9853 20.9853 23 18.5 23L5.5 23C3.01472 23 1 20.9853 1 18.5Z"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                        <path
                          d="M1 5.5C1 3.01472 3.01472 1 5.5 1L18.5 1C20.9853 1 23 3.01472 23 5.5C23 7.98528 20.9853 10 18.5 10L5.5 10C3.01472 10 1 7.98528 1 5.5Z"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                        <path
                          d="M4 5.5C4 4.67157 4.67157 4 5.5 4C6.32843 4 7 4.67157 7 5.5C7 6.32843 6.32843 7 5.5 7C4.67157 7 4 6.32843 4 5.5Z"
                          fill="#21407D"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                        <path
                          d="M17 18.5C17 17.6716 17.6716 17 18.5 17C19.3284 17 20 17.6716 20 18.5C20 19.3284 19.3284 20 18.5 20C17.6716 20 17 19.3284 17 18.5Z"
                          fill="#21407D"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                      </svg>
                    </span>
                    <label onClick={() => setMaster(!master)} aria-expanded={master}>
                      {t('page.sidebar_masters')}
                    </label>
                    <small
                      className="right-side-set-arrow"
                      onClick={() => setMaster(!master)}
                      aria-expanded={master}>
                      <FontAwesomeIcon
                        icon={master ? faChevronUp : faChevronDown}
                        className="dropdown_icon"
                      />
                    </small>
                  </span>
                  <Collapse in={master} className="p-0">
                    <ul className="submenu">
                      {shouldShowRoute('translations') && (
                        <li>
                          <NavLink
                            to="/translations/list"
                            className={translationsLink ? 'active' : ''}>
                            {t('page.sidebar_translations')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('email-template') && (
                        <li>
                          <NavLink
                            to="/email-template/list"
                            className={emailTemplateLink ? 'active' : ''}>
                            {t('page.sidebar_emailtemplates')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('faq-category') && (
                        <li>
                          <NavLink
                            to="/faq-category/list"
                            className={faqCategoryLink ? 'active' : ''}>
                            {t('page.sidebar_faq_category')}
                          </NavLink>
                        </li>
                      )}
                      {shouldShowRoute('faq') && (
                        <li>
                          <NavLink to="/faq/list" className={faqsLink ? 'active' : ''}>
                            {t('page.sidebar_faq')}
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
              </ul>
            ) : null}
            {shouldShowRoute('general-setting') ? (
              <ul className="main-submenu">
                <li>
                  <span className="sidebar-li-arrow">
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1 18.5C1 16.0147 3.01472 14 5.5 14L18.5 14C20.9853 14 23 16.0147 23 18.5C23 20.9853 20.9853 23 18.5 23L5.5 23C3.01472 23 1 20.9853 1 18.5Z"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                        <path
                          d="M1 5.5C1 3.01472 3.01472 1 5.5 1L18.5 1C20.9853 1 23 3.01472 23 5.5C23 7.98528 20.9853 10 18.5 10L5.5 10C3.01472 10 1 7.98528 1 5.5Z"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                        <path
                          d="M4 5.5C4 4.67157 4.67157 4 5.5 4C6.32843 4 7 4.67157 7 5.5C7 6.32843 6.32843 7 5.5 7C4.67157 7 4 6.32843 4 5.5Z"
                          fill="#21407D"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                        <path
                          d="M17 18.5C17 17.6716 17.6716 17 18.5 17C19.3284 17 20 17.6716 20 18.5C20 19.3284 19.3284 20 18.5 20C17.6716 20 17 19.3284 17 18.5Z"
                          fill="#21407D"
                          stroke="#21407D"
                          strokeWidth="2"
                        />
                      </svg>
                    </span>
                    <label onClick={() => setSetting(!setting)} aria-expanded={setting}>
                      {t('page.sidebar_settings')}
                    </label>
                    <small
                      className="right-side-set-arrow"
                      onClick={() => setSetting(!setting)}
                      aria-expanded={setting}>
                      <FontAwesomeIcon
                        icon={setting ? faChevronUp : faChevronDown}
                        className="dropdown_icon"
                      />
                    </small>
                  </span>
                  <Collapse in={setting} className="p-0">
                    <ul className="submenu">
                      {shouldShowRoute('general-setting') && (
                        <li>
                          <NavLink to="/setting/general" className={generalLink ? 'active' : ''}>
                            {t('page.sidebar_generals')}
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </Collapse>
                </li>
              </ul>
            ) : null}
          </nav>
        </div>
      </aside>
    </>
  );
};
Sidebar.propTypes = {
  toggleClass: PropTypes.any.isRequired,
  t: PropTypes.func
};
export { Sidebar };
